import React from 'react';
import { BaseTemplate } from '@templates';
import { Helmet } from 'react-helmet';

import '@style/base.scss';
import '@style/grid.scss';

export default ({ pageContext }) => {
  return (
    <BaseTemplate>
      <div className="page-section">
        <div
          dangerouslySetInnerHTML={{
            __html: pageContext.content
          }}
        />
      </div>
    </BaseTemplate>
  );
};
